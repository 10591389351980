import React from "react";

const BetIcon = () => {
    return (<svg width="46" height="41" viewBox="0 0 46 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.68336 38.5289L2.17742 28.7393C2.10016 28.6236 2.04658 28.4938 2.01976 28.3573C1.99294 28.2208 1.99343 28.0803 2.02118 27.944C2.04893 27.8077 2.10341 27.6782 2.18146 27.563C2.25951 27.4479 2.3596 27.3493 2.47594 27.2731L6.58495 24.6391C6.81504 24.4918 7.09304 24.4388 7.36119 24.4911C7.62933 24.5434 7.86702 24.697 8.02486 24.92L14.6625 34.578C14.7406 34.6926 14.7953 34.8215 14.8234 34.9573C14.8516 35.0932 14.8526 35.2332 14.8265 35.3694C14.8004 35.5057 14.7477 35.6354 14.6713 35.7512C14.595 35.867 14.4965 35.9666 14.3815 36.0442L10.1496 38.8187C10.0344 38.8964 9.90506 38.9505 9.7689 38.978C9.63274 39.0055 9.49248 39.0057 9.35621 38.9788C9.21994 38.9519 9.09034 38.8983 8.97486 38.8211C8.85939 38.7439 8.76032 38.6446 8.68336 38.5289Z" stroke="#FFFAFA" strokeWidth="2.63398" strokeLinecap="round" />
        <path d="M8.75391 25.9121L15.6023 21.6099C15.8217 21.4707 16.0674 21.3778 16.324 21.3371C16.5807 21.2963 16.843 21.3085 17.0949 21.3729C19.5269 21.9962 26.8406 24.0068 29.536 24.9551C31.5642 25.6662 32.3807 27.6681 31.5642 28.9851C30.4579 30.7411 28.7722 30.5742 27.5605 30.2318L27.0776 30.0562L22.2838 28.0105" stroke="#FFFAFA" strokeWidth="2.63398" strokeLinecap="round" />
        <path d="M14.0645 33.6824L16.3033 32.4006L28.9728 35.9125C29.1028 35.9494 29.2392 35.9574 29.3727 35.9362C29.5061 35.915 29.6333 35.865 29.7454 35.7896C31.5014 34.6482 39.1487 29.591 42.9153 26.9307C43.2207 26.7612 43.4792 26.5185 43.6675 26.2244C43.8559 25.9303 43.9681 25.594 43.9942 25.2457C44.0204 24.8974 43.9595 24.5481 43.8172 24.2292C43.6748 23.9103 43.4554 23.6318 43.1787 23.4187C42.7405 23.024 42.1859 22.7823 41.5984 22.7301C41.011 22.6778 40.4224 22.8178 39.9214 23.1289C38.6044 23.8665 34.9432 26.5882 34.9432 26.5882" stroke="#FFFAFA" strokeWidth="2.63398" strokeLinecap="round" />
        <path d="M23.0117 2.00571V18.0501H30.8382C30.8382 18.0501 36.4742 18.4366 36.4742 13.0869C36.4742 13.0869 36.3549 10.3094 33.859 9.32636C34.4463 8.90727 34.9185 8.347 35.2321 7.69726C35.5457 7.04753 35.6906 6.32926 35.6534 5.60876C35.6534 5.60876 36.0543 2.01525 31.2963 2.00571C26.5384 1.99616 23.0117 2.00571 23.0117 2.00571Z" stroke="#FFFAFA" strokeWidth="2.67579" />
    </svg>)
}

const SendIcon = () => {
    return (<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.5988 2.40163L20.0741 25.9263M2.51839 17.3383L42.9042 2.0533C43.0499 1.99757 43.2086 1.98523 43.3611 2.01775C43.5137 2.05028 43.6535 2.12628 43.7638 2.23657C43.8741 2.34687 43.9501 2.48675 43.9827 2.6393C44.0152 2.79186 44.0028 2.95057 43.9471 3.09626L28.6621 43.482C28.6013 43.6354 28.495 43.7665 28.3575 43.8576C28.22 43.9488 28.0579 43.9956 27.8929 43.9919C27.728 43.9882 27.5682 43.934 27.4349 43.8367C27.3017 43.7394 27.2014 43.6037 27.1477 43.4477L20.3426 26.6785C20.2633 26.4409 20.1298 26.2249 19.9526 26.0478C19.7755 25.8706 19.5595 25.7371 19.3219 25.6578L2.55272 18.8588C2.39474 18.8063 2.25687 18.7062 2.15797 18.5722C2.05906 18.4383 2.00396 18.2771 2.00021 18.1107C1.99645 17.9442 2.04422 17.7807 2.13697 17.6424C2.22973 17.5042 2.36295 17.3979 2.51839 17.3383Z" stroke="#FFFAFA" strokeWidth="3.23086" strokeLinecap="round" stroke-linejoin="round" />
    </svg>)
}

const CrossIcon = ({ size }) => {
    return (<svg width={size} height={size} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.54504 6.54498L42.0575 42.455" stroke="#802626" strokeWidth="3" />
        <path d="M42.455 6.54498L6.9425 42.455" stroke="#802626" strokeWidth="3" />
    </svg>)
}

export { BetIcon, SendIcon, CrossIcon };
import { default as multiple } from './bet_types/multiple.png'
import { default as number } from './bet_types/number.png'

export { default as next } from './next.png';
export { default as previous } from './previous.png';
export { default as google } from './google.png';
export { default as viarezo } from './viarezo.png';

export { default as logout } from './logout.png';

const bet_types_images = { "multiple": multiple, "number": number };
const bet_types_names = { "multiple": "Choix multiples", "number": "Nombres" }

export { bet_types_images, bet_types_names };

